import * as React from "react"

import Layout from '../../components/layout/layout'

const pageTitle = "OFCA Approved List"

const IndexPage = () => {
    return (
        <Layout pageTitle={pageTitle}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <h3 className="pt-4 pb-4">
                            <a href="/blog/ofca-approved-list/">香港 OFCA 已經登記之 Amateur Radio 通訊器材</a>
                        </h3>

                        <p className="text-muted">May 28, 2015</p>


                        <img
                            src="//s3-ap-northeast-1.amazonaws.com/agilehobby/photos/uuid/079d4897-b32f-4d8b-9b1b-6165a9d08373.jpg"
                            className="img-responsive img-thumbnail"/>

                        <p>以下是最新香港 OFCA 已經登記之 Amateur Radio 通常器材。買機用以下型號去 OFCA 不用通過新機測試，煩小 D。<br/>資訊來源: OFCA 網站。
                        </p>

                        <p>最后更新: 2016 年 3 月 29 日<br/>
                            對比上一次更新的新機:<br/>
                            BAO BF-5RIV<br/>
                            CLA 308<br/>
                            HYT MD780 U(1)<br/>
                            ICO IC-2730A<br/>
                            ICOM IC-7300<br/>
                            KEN TH-255A<br/>
                            KEN TS-950SD<br/>
                            MOT AZM27JNH9LA2AN<br/>
                            SMP SMP-328<br/>
                            T1 超級章魚<br/>
                            TYT MD-390<br/>
                            WEI VEV-789<br/>
                            WOU KG-D900<br/>
                            WOU KG-D901<br/>
                            WOU KG-UV8D(PLUS)<br/>
                            WOU KG-UV9D(PLUS)<br/>
                            YAE FTDX 1200<br/>
                            YAE FTM-400XDR<br/>
                            YOU TJ2B

                        </p>

                        <p>移除之電台機:<br/>
                            TYT TH-9000<br/>
                            YAE FT-650


                        </p>

                        <pre>{`
ADI AT-18
ADI AT-200
ADI SENDER-145
ADI SENDER-450
AEA PK232
AKD 6001
ALI ALM-201
ALI ALR-22HT
ALI DJ-160TE
ALI DJ-162TD
ALI DJ-180
ALI DJ-180T
ALI DJ-182C1
ALI DJ-190T
ALI DJ-191T
ALI DJ-195
ALI DJ-496
ALI DJ-500SX
ALI DJ-500T
ALI DJ-560SX
ALI DJ-580
ALI DJ580E
ALI DJ-580E
ALI DJ-580T
ALI DJ-582
ALI DJ-582T
ALI DJ-596T
ALI DJ-C1
ALI DJ-C5E
ALI DJ-F1T
ALI DJ-G1
ALI DJ-G1E
ALI DJ-G5E
ALI DJ-G7E
ALI DJ-G7EG
ALI DJ-S11T
ALI DJ-S1E
ALI DJ-S1T
ALI DJ-S41T
ALI DJ-V17T
ALI DJ-V5E
ALI DJ-X1
ALI DJ-X10
ALI DR112T
ALI DR-119T
ALI DR-11C1
ALI DR130
ALI DR-130
ALI DR-130T
ALI DR-130TE1
ALI DR-135E
ALI DR-135T
ALI DR-140E
ALI DR150E
ALI DR-435T
ALI DR-590
ALI DR-592
ALI DR-599
ALI DR-599T
ALI DR-605E
ALI DR-605TE2
ALI DR-610E
ALI DR-620C
ALI DR-620C PRO
ALI DR-620T
ALI DR-635E
ALI DR-M06
ALI DR-M06SX
ALI DX-70
ALI DX-70TH
AME AL-811H
AOR A0R-3000A
AOR AR-2002
AOR AR-240
AOR AR3000A
AOR AR-3000A
AOR AR5000
AOR AR-8000
AOR AR8200 MARK III
AOR AR8600 MARK2
AZD AZ-11
AZD AZ-61
AZD PCS-300
AZD PCS4000
AZD PCS-4000
AZD PCS-5000
AZD PCS-6
AZD PCS-6000
AZD PCS-6500
AZD PCS-7000H
AZD PCS-7500H
AZD PSC-5000
AZD TEST123
BAO BF-5RIV
BAO BF-658
BAO BF-888S
BAO BF-F8+
BAO BF-UV5R II
BAO JOK
BAO UV-3R
BAO UV-3R+
BAO UV-5R
BAO UV-5R+PLUS
BAO UV-5RA+
BAO UV-5RB
BAO UV-5RE
BAO UV-5RE PLUS
BAO UV-82
BAOJIE BJ-UV99
BEL LS-202
BEL LS20X
BFD BF-5118
BFD BF-8900UH
BORISTONE BR-3
BORISTONE BR-3(7RL)
CHU CQ-UV3R
CIR DR-110
CIR PR-100HX
CLA 308
COD NGT-SR
CTE CT1800
DAI MT-20X
DRA SSR-1
DRA TR7
EEC EEC
EFJ 242-5113-622-BA
EFJ 242-5132-410-DA
EHR ALPHA 89
ELC K2
ELC K3
ELC KX3
ELE BC-100
ELECRAFT KX1
ETTUS B-200
FDC FD-150A
FDC FD-268A
FDC FD-450A
FDK MULTI-725X
FLEXRADIO FLEX-1500
FLEXRADIO FLEX-3000
FLEXRADIO FLEX-6500
HAN NIL
HLT HLT-6100 PLUS
HO MTX20
HQT DH8800
HYT MD780 U(1)
HYT PD680UM
HYT PD688
HYT PD780
HYT PD788
HYT TC-585U(1)
HYT TD360 U
ICO 02N
ICO 2GXAT
ICO 2SAT
ICO 2SR
ICO IC- 6
ICO IC-02A
ICO IC-02AT
ICO IC-02E
ICO IC-02N
ICO IC-04AT
ICO IC-1200A/E
ICO IC-2000H
ICO IC-207
ICO IC-207H
ICO IC-208H
ICO IC-211
ICO IC-215
ICO IC-21A
ICO IC-2200H
ICO IC-228H
ICO IC-229
ICO IC-229C
ICO IC-229H
ICO IC-22U
ICO IC-2350H
ICO IC-24
ICO IC-2400A
ICO IC-2410A
ICO IC-2410H
ICO IC-24AT
ICO IC-24ET
ICO IC-251
ICO IC-251A
ICO IC255A
ICO IC25A
ICO IC-25H
ICO IC-26
ICO IC-260
ICO IC-260A
ICO IC-2710H
ICO IC-271A
ICO IC-271H
ICO IC-2720H
ICO IC-2730A
ICO IC-275
ICO IC-275A
ICO IC-275H
ICO IC-275HA
ICO IC-27H
ICO IC-2800H
ICO IC-281H
ICO IC-2820H
ICO IC-28A
ICO IC-28H
ICO IC-290H
ICO IC-2A
ICO IC2AT
ICO IC-2AT
ICO IC-2E
ICO IC-2G
ICO IC-2GA
ICO IC-2GAT
ICO IC-2GXA
ICO IC2GXAT
ICO IC-2GXAT
ICO IC2KL
ICO IC-2N
ICO IC-2S
ICO IC-2SA
ICO IC-2SAT
ICO IC2SRA
ICO IC-2SRA
ICO IC-2SRE
ICO IC-2ST
ICO IC3210A
ICO IC-3210A
ICO IC-3230E
ICO IC-32A
ICO IC-32AT
ICO IC-32E
ICO IC-375
ICO IC-3N
ICO IC-449A
ICO IC-481H(ASIA)
ICO IC-505
ICO IC-551D
ICO IC-575
ICO IC-575D
ICO IC-7000
ICO IC-701
ICO IC-703
ICO IC-706
ICO IC-706 MKII
ICO IC-706MK2
ICO IC-706MKII
ICO IC-706MKIIG
ICO IC-7100
ICO IC-718
ICO IC-720
ICO IC-7200
ICO IC-720A
ICO IC-723
ICO IC-725
ICO IC-726
ICO IC-729
ICO IC-735
ICO IC-736
ICO IC-738
ICO IC-7400
ICO IC-745
ICO IC-746
ICO IC-746PRO
ICO IC751
ICO IC-751
ICO IC-751A
ICO IC756
ICO IC-756
ICO IC-756PRO
ICO IC-756PROII
ICO IC-756PROIII
ICO IC-7600
ICO IC-760PRO
ICO IC-765
ICO IC-7700
ICO IC-775DSP
ICO IC-780
ICO IC-7800
ICO IC-781
ICO IC-80AD
ICO IC-820
ICO IC-820H
ICO IC-821H
ICO IC-900A/UX-29A
ICO IC-9100
ICO IC-910H
ICO IC-91A
ICO IC-92AD
ICO IC-970D
ICO IC-970H
ICO IC-E7
ICO IC-E80D
ICO IC-F10
ICO IC-F11
ICO IC-F40LT-2
ICO IC-FR6000
ICO IC-P21A
ICO IC-P2AT
ICO IC-P2CT
ICO IC-P2CT-1
ICO IC-P3
ICO IC-P3T
ICO IC-P7
ICO IC-P7A
ICO IC-Q7A
ICO IC-Q7E
ICO IC-R1
ICO IC-R10
ICO IC-R100
ICO IC-R2
ICO IC-R5
ICO IC-R6
ICO ICR7000
ICO IC-R7000
ICO ICR71E
ICO IC-R71E
ICO IC-R72
ICO IC-T21
ICO IC-T21A
ICO IC-T21CP
ICO IC-T22
ICO IC-T22A
ICO IC-T2A
ICO IC-T31CP
ICO IC-T41A
ICO IC-T70A
ICO IC-T70E
ICO IC-T7A
ICO IC-T7E
ICO IC-T7H
ICO IC-T8
ICO IC-T81A
ICO IC-T8A
ICO IC-T8E
ICO IC-T90
ICO IC-T90A
ICO IC-U2
ICO IC-U2AT
ICO IC-U4AT
ICO IC-U68
ICO IC-U82
ICO IC-V68
ICO IC-V8
ICO IC-V80
ICO IC-V8000
ICO IC-V82
ICO IC-W21A
ICO IC-W21AT
ICO IC-W21T
ICO ICW2A
ICO IC-W2A
ICO ICW31A
ICO IC-W31A
ICO IC-W32A
ICO IC-W32E
ICO IC-Z1A
ICO ID-31
ICO ID-51
ICO ID-800H
ICO ID-880H
ICO ID-91
ICO ID-E880
ICO ID-RP2000V
ICO ID-RP4000V
ICO R7000
ICO R-7000
ICO TC-T21A
ICO W21AT
ICOM IC-2730D
ICOM IC-7300
ICOM ID-5100D
JAPAN IC2GHAT
JIL SX200
JIN JT-208(1)
JIN JT308
JIN JT-308(1)
JIN JT-988(VHF)
JRC JST-135D
JRC JST-145D
JRC JST-245
KAN TK-918
KDK FM 2025J
KEN 231
KEN 7950
KEN 9130
KEN R-1000
KEN R2000
KEN TH-205AT
KEN TH-21
KEN TH-215
KEN TH215A
KEN TH-215A
KEN TH-21AT
KEN TH-22
KEN TH-22A
KEN TH-22AT
KEN TH231A
KEN TH-255A
KEN TH-25A
KEN TH-25AT
KEN TH-25G
KEN TH-26A
KEN TH-26AT
KEN TH-26AT(3)
KEN TH-27A
KEN TH-28
KEN TH-28A
KEN TH-415
KEN TH-415A
KEN TH-42AT
KEN TH46A/AT
KEN TH-7
KEN TH-75
KEN TH-75A
KEN TH-77
KEN TH-77A
KEN TH-78
KEN TH78A
KEN TH-78A
KEN TH78Q
KEN TH-78Q
KEN TH-79
KEN TH-79A
KEN TH-79E
KEN TH-89
KEN TH-D72E
KEN TH-D7A(G)
KEN TH-D7E
KEN TH-F27
KEN TH-F28
KEN TH-F28Q
KEN TH-F48
KEN TH-F6A
KEN TH-F7A
KEN TH-F7E
KEN TH-G71A
KEN TH-K28
KEN TH-K2AT
KEN TH-K4AT
KEN TH-K7
KEN TK-208(1)
KEN TK-2107(1)
KEN TK-2160
KEN TK-240
KEN TK-248ST
KEN TK-278(1)
KEN TK-278(M2)
KEN TK-278G
KEN TK-705D
KEN TKR-700RA
KEN TKR-800RA
KEN TM 251A
KEN TM-201A
KEN TM211A
KEN TM-221A
KEN TM-231A
KEN TM-241
KEN TM241A
KEN TM-241A
KEN TM-241S
KEN TM-241SQ
KEN TM251A
KEN TM-251A
KEN TM-2550A
KEN TM-2570
KEN TM-2570A
KEN TM-261A
KEN TM-431A
KEN TM-431S
KEN TM-441A
KEN TM-701A
KEN TM-721A
KEN TM-721E
KEN TM-721G
KEN TM-731A
KEN TM-731E
KEN TM-732A
KEN TM-733
KEN TM733A
KEN TM-733A
KEN TM741
KEN TM741A
KEN TM-741A
KEN TM-742A
KEN TM-D700A
KEN TM-G707A
KEN TM-V71
KEN TM-V71A
KEN TM-V7A
KEN TR-2300
KEN TR-2500
KEN TR-2600
KEN TR-2600A
KEN TR-751
KEN TR751A
KEN TR-751D
KEN TR-7930
KEN TR-7950
KEN TR851A
KEN TR-9130
KEN TR-9300
KEN TS-130S
KEN TS-130V
KEN TS-140S
KEN TS-2000
KEN TS-430S
KEN TS440S
KEN TS-440S
KEN TS450S
KEN TS-450S
KEN TS-480HX
KEN TS-480SAT
KEN TS50
KEN TS50S
KEN TS-50S
KEN TS-570S
KEN TS-590S
KEN TS-60
KEN TS-60S
KEN TS-660
KEN TS-670
KEN TS-680
KEN TS680S
KEN TS-680S
KEN TS-680V
KEN TS-690D
KEN TS690S
KEN TS-690S
KEN TS-690V
KEN TS-700A
KEN TS-711
KEN TS-711A
KEN TS-711A/E
KEN TS-790
KEN TS-790A
KEN TS-811
KEN TS-811A
KEN TS-811A/B
KEN TS820
KEN TS-820
KEN TS-850
KEN TS-850S
KEN TS-870S
KEN TS-930
KEN TS-930S
KEN TS-940S
KEN TS-950S
KEN TW-4000A
KEN UB2-L3
KEY C-150
KIN TG2118(1)
KINGPO K-303P
KOM KH142
KOM KH-142
KYD NC-950B(UHF)
KYD TK-338
LIN LT-2188
LIN LT-2188(1)
LIN LT-2268
LIN LT-2288
LIN LT-2288(3)
LIN LT-2288X4W
LIN LT-3188
LIN LT-3188(1)
LIN LT-3260
LIN LT-3260(1)
LIN LT-3288
LIN LT-3300
LIN LT-5288
LIN LT-5800
LIN LT-6100
LIN LT-6100PLUS UHF
LIN LT-6100PLUS VHF
LIN LT-6188(1)
LIN LT-6200
LIN LT-6288
LIN LT-6288(2)
LIN LT-6600 UHF
LIN LT-71A
LIN LT-7700D (UHF)
LIN LT-9600
LIN LT-9800
LIS LS-7800 (UHF)
MAR ST-1000
MARANTZ C160
MAX HR146
MAX HR-146
MAY KH-603
MEG MT-6800 PLUS
MIC MK2020
MIDLAND CT790
MIZ HANOI
MIZ MX-18S
MIZ MX-21S
MOT AAH02RDH9JA2AN
MOT AAH25KDF9AA5AN
MOT AAH25KDH9AA6ANF
MOT AAH25KDN9DU8AN
MOT AAH25RDH9AA6AN
MOT AAH25RDN9DU8AN
MOT AAH25SDN9DU8AN
MOT AAH38KDC9AA3AN
MOT AAH38KDH9DU6AN
MOT AAH38RDH9DU6AN
MOT AAH55JDH9LA1AN
MOT AAH55QDH9JA1AN
MOT AAH55QDH9LA1AN
MOT AAH56RDN9KA1AN
MOT AAH88QCP9JA2AN
MOT AP10
MOT AP50
MOT AZH01QDC9JA2AN
MOT AZH02RDC9JA2AN
MOT AZH02RDH9JA2AN
MOT AZH03QDK8AA9
MOT AZH25KDC9AA3
MOT AZH25KDH9AA6
MOT AZH25KDH9AN6
MOT AZH25RDC9AA3
MOT AZH25RDC9AA4
MOT AZH25RDC9AN3
MOT AZH25RDH9AA6
MOT AZH25RDH9CK6
MOT AZH34KDC9AA3
MOT AZH34RDC9AA3
MOT AZH38KDC9AA3
MOT AZH38KDH9AA6
MOT AZH38RDC9AA3
MOT AZH38RDH9AA6
MOT AZH49KDH8AA9
MOT AZH49QCH8AA9
MOT AZH49RCH8AA9
MOT AZH55JDC9JA1AN
MOT AZH55JDH9JA1AN
MOT AZH55JDH9LA1AN
MOT AZH55QDH9JA1AN
MOT AZH55QDH9LA1AN
MOT AZH56JDN9KA1AN
MOT AZH56RDN9KA1AN
MOT AZH88JCP9JA2AN
MOT AZH88QCP9JA2AN
MOT AZM08JHF4AA3AA
MOT AZM08RHF6AA3AA
MOT AZM25KHF9AA5
MOT AZM25RKF9AA5
MOT AZM27JNH9LA2AN
MOT AZM27JQH9LA2AN
MOT AZM27QNH9JA2AN
MOT AZM27QPH9LA2AN
MOT AZM27QPR9JA7AN
MOT AZM28QPN9KA2AN
MOT AZP93VNB20H2AA
MOT AZP93VND20H2AA
MOT AZP93XFT20H2AA
MOT AZP94VNB20H1AA
MOT AZP94VNB20H2AA
MOT AZP94VNH20H2AA
MOT CP10
MOT GM 300
MOT GP68
MOT GP-88
MOT GR300
MOT H01JDC9AA3A
MOT H01JDH9AA7A
MOT H01KDC9AA1BN NX
MOT H01KDC9AA1CN NX
MOT H01KDC9AA3BN
MOT H01KDC9AA3CN
MOT H01KDC9AA3DN
MOT H01KDC9AN3AN
MOT H01KDD9AA4AN
MOT H01KDD9DB4AN
MOT H01KDD9PW1BN
MOT H01KDH9AA7AN
MOT H01KDH9PA3AN
MOT H01KDH9PW1BN
MOT H01RDC9AA3DN
MOT H01RDC9DB3AN
MOT H01RDD9AA4AN
MOT H01RDD9DB4AN
MOT H01RDD9PW1BN
MOT H01RDF9PW1BN
MOT H01RDH4AA7A
MOT H01RDH9AA7AN
MOT H01RDH9DB7AN
MOT H01RDH9PA3AN
MOT H01RDH9PW1BN
MOT H04KDC9PW5AN
MOT H04KDF9PW7AN
MOT H04KDH9PW7AN
MOT H04RDC9PW5AN
MOT H04RDF9PW7AN
MOT H04RDH9PW7AN
MOT H04SDH9PW7AN
MOT H05JDD9AA4B
MOT H05KDD9AA4AN
MOT H05KDD9AA4BN
MOT H05KDD9AA4DN
MOT H05KDH9AA7DN
MOT H05RDD4AA4B
MOT H05RDD9AA4DN
MOT H09KDF9PW7BN
MOT H09KDH9PW7BN
MOT H09RDC9PW5BN
MOT H09RDF9PW7BN
MOT H09RDH9PW7AN
MOT H09RDH9PW7BN
MOT H18KCN9PW9AN
MOT H18KEF9PW6AN
MOT H18KEH9PW7AN
MOT H18QCN9PW9AN
MOT H18QDH9PW7AN
MOT H43GCJ7190CN
MOT H43QXJ7139CN
MOT H43QXK7139CN
MOT H43QXN7139AN
MOT H43TUK7139BN
MOT H44GCJ7190AN
MOT H44GCU7100BN
MOT H44QXJ7139CN
MOT H44QXK7139CN
MOT H46KDF9PW6AN
MOT H46KDH9PW7AN
MOT H46KDH9PW7BN
MOT H46QDH9PW7BN
MOT H55QDH9LA1AN
MOT H99QX+052H
MOT H99QX+053H
MOT H99QX+059H
MOT H99QX+080H
MOT H99QX+099H
MOT H99QX+102H
MOT H99QX+103H
MOT H99QX+104H
MOT H99QX+106H
MOT H99QX063H
MOT H99SA+053H
MOT H99SA+061H
MOT H99SA+063H
MOT HO1KDH9AA7AN
MOT JJH31RDC9AA3AT
MOT JT-1000
MOT M01RHM9PW5BN
MOT M20KSS9PW1AN
MOT M20QSS9PW1AN
MOT M21QSM9PW1AN
MOT M33GMC00D2AA
MOT M33GMC29C1AA
MOT M34GMC20D3AA
MOT M44GMC20A
MOT M44GMC20A1AA
MOT M44GMC20D1
MOT M44GMC20D3
MOT M44GMC29C1
MOT M44GRC90C2AA
MOT MDH34SAJ9109AN
MOT MDH55JDH9LA1AN
MOT MDH55QDH9LA1AN
MOT MTO-15A
MOT P23QLC00A1AA
MOT P23XNT20G2AA
MOT P24QLC20A1AA
MOT P43QLC00B1AA
MOT P43QLC00E1AA
MOT P43VMC00C1AA
MOT P43VMC20C1AA
MOT P44QLC20E2AA
MOT P44VMC20C1AA
MOT P44VMC20C2AA
MOT P93YPC00A1AA
MOT P93YPC00A2AA
MOT P93YPC00C1AA
MOT P93YPC00C2AA
MOT P93YPC00D1AA
MOT P93YPC20D2AA
MOT P94YPC20C2AA
MOT RADIUS CP50
MOT RADIUS R100
MOT XIR P8668
MOT SL MDH81QCN9NA2AN
MUA A28
NAN NF-368
NAN NF-6600
NAN NF-669
NEU SM-1645HS
NIL 2M HAM REPEATER
NIL 6M REPEATER
NIL 70CM REPEATER
NIS SX-100
NKT NKT-R3
NSA HKP008
NUT AT-81A UHF
NUT AT-81A VHF
NUT AT-828
NUT AT-UVB
PAC BLMC365
PHI FM828
PLA6902 TBR-115
PRO GPT-2608
PRO GPT-599
PUX PX-2R
PUX PX-333U (UHF)
PUX PX-728(UHF)
PUX PX-777    (UHF)
PUX PX-777    (VHF)
PUX PX-888(UHF)
QUA TG-42AT (UHF)
QUA TG-42AT(VHF)
QUA TG-45AT   (UHF)
QUA TG-45AT   (VHF)
QUA TG-46AT (UHF)
QUA TG-5A
QUA TG-6A
QUA TG-K4AT
QUA TG-K4AT   (UHF)
QUA TG-K4AT   (VHF)
QUA TG-UV (VHF&amp;UHF;)
QUA TG-UV2
QYT KT-8900
QYT KT-UV980
RAC TRA967B/3
RAD HTX-10
RAD PRO-2039
RCA PRC25/RT-505B
REA HTX-100
REA HTX202
REA HTX-202
REX RL-102
RUN X5
SAN ATS-818CS
SEI PCV-150
SHI SC525EG
SHI SH503KG
SMP SMP319
SMP SMP-328
SMP SMP418
SMT SMP818
SNOPOW M8
SON AIR-7
SON IC-2001D
SON ICF-PRO80
STA AX-700
STA AX700E
STA C101
STA C108
STA C111
STA C112
STA C-112
STA C-120
STA C1208D
STA C120E
STA C150
STA C-150
STA C151
STA C-151
STA C160
STA C-160
STA C168
STA C-168
STA C188
STA C401
STA C412
STA C450
STA C460
STA C50
STA C500
STA C-501
STA C508
STA C-508A
STA C520
STA C-520
STA C528
STA C-528
STA C550
STA C558
STA C-558
STA C5600D
STA C701
STA C710
STA C-88
STA FTM-10
STA HX-260V
STA HX600T
STA RP80U
STA VX-8
STAR C130
SUI CT-08
T1 超級章魚
TEC 1220
TELT TLT-E61
TEN 153
TESUNHO TH-UV7R
TOK HL-45B
TOK HT75
TOK HT-750
TOKA TC-168
TON T-801
TONFA UV-985
TRI TL-922
TRI TR-7950
TRI TR-9000
TRI TR-9300
TRI TS-700GII
TRI TS-780
TRIO TS-600
TYT MD-380
TYT MD-390
TYT TH-9000D
TYT TH-9800
TYT TH-F8
TYT TH-UV3R
TYT TH-UV818
TYT TH-UVF1
TYT TH-UVF8D
UNI UA-2N
VER VX-160U
VER VX-160V
VER VX-180V
VER VX-2200   (VHF)
VER VX-351-SD0B-5
VER VX-351-SG6B-5
VER VX-354-CD0B-5
VER VX-354-CG6B-5
VER VXR-5000
VERT FT-817
VERT FT-857
VERT VX-2200-D0-50
VERT VX-8
WAC UV-5R
WAC WUV-6R
WEI D8
WEI V1000     (UHF)
WEI V1000     (VHF)
WEI V6 UHF
WEI VEV-3288S (UHF)
WEI VEV-3288S (VHF)
WEI VEV-5288/6288 V
WEI VEV-5299  (VHF)
WEI VEV-6299
WEI VEV-789
WEI VEV-V17 U+V
WEI VEV-V8(UHF)
WEI VEV-V8(VHF)
WEL WS1000E
WES FD-1
WES SV16
WES SV-16
WES SV-82
WES SV-860
WOU KG-669    (UHF)
WOU KG-669    (VHF)
WOU KG-689    (UHF)
WOU KG-689    (VHF)
WOU KG-801
WOU KG-816
WOU KG-889(UV)
WOU KG-D900
WOU KG-D901
WOU KG-UV6D
WOU KG-UV8D
WOU KG-UV8D(PLUS)
WOU KG-UV920R(III)
WOU KG-UV9D
WOU KG-UV9D(PLUS)
WOU KG-UVD1
WOU KG-UVD1P
YAE 2600FT
YAE 729
YAE F7-51R
YAE FL-2100E
YAE FL-2100Z
YAE FL-7000
YAE FRG-7000
YAE FRG-7700
YAE FRG-9600
YAE FRG-965
YAE FT DX 3000
YAE FT-10
YAE FT100
YAE FT-100
YAE FT1000
YAE FT-1000
YAE FT-1000D
YAE FT-1000MP
YAE FT-1000MP MARKV
YAE FT-100D
YAE FT-1011
YAE FT101ZD
YAE FT-1021
YAE FT-1021X
YAE FT-10R
YAE FT-11
YAE FT-11R
YAE FT-1200
YAE FT-1500M
YAE FT-1802M
YAE FT-1807M
YAE FT-1900R
YAE FT-1907R
YAE FT-1DR
YAE FT-2000
YAE FT-204
YAE FT-208R
YAE FT-209H
YAE FT-209R
YAE FT-209RH
YAE FT-212
YAE FT-212H
YAE FT-212RH
YAE FT-2200
YAE FT-2200A
YAE FT-2200B
YAE FT-227
YAE FT23
YAE FT-23
YAE FT23R
YAE FT-23R
YAE FT-2400
YAE FT-2400H
YAE FT2500
YAE FT-2500M
YAE FT-250R
YAE FT26
YAE FT-26
YAE FT-2600M
YAE FT-2700RH
YAE FT-270R
YAE FT-277R
YAE FT-2800M
YAE FT-2900R
YAE FT-290R
YAE FT2D
YAE FT-3000M
YAE FT-301D
YAE FT-3700
YAE FT-40R
YAE FT-411
YAE FT-411E
YAE FT-415
YAE FT-416
YAE FT-450
YAE FT-450AT
YAE FT-450D
YAE FT-470
YAE FT-4700RH
YAE FT-4900M
YAE FT-50R
YAE FT-51
YAE FT-5100
YAE FT51R
YAE FT-51R
YAE FT-530
YAE FT-60R
YAE FT-680
YAE FT-690MKII
YAE FT-690R II
YAE FT-7100M
YAE FT-712H
YAE FT-726
YAE FT-726R
YAE FT-727G
YAE FT-728
YAE FT729
YAE FT-729
YAE FT736
YAE FT-736
YAE FT-736MX
YAE FT-736R
YAE FT-747GX
YAE FT757GX
YAE FT757GX11
YAE FT-757GXII
YAE FT-767GX
YAE FT-7800E
YAE FT-7800R
YAE FT-7900R
YAE FT-8000
YAE FT-8000R
YAE FT-8100
YAE FT-8100R
YAE FT-815
YAE FT-817
YAE FT-817ND
YAE FT-840
YAE FT-847
YAE FT-850
YAE FT-8500
YAE FT-857
YAE FT-857D
YAE FT-8800R
YAE FT-890
YAE FT-8900R
YAE FT-897
YAE FT-897D
YAE FT-900
YAE FT-901DM
YAE FT-90R
YAE FT-9130
YAE FT920
YAE FT-920
YAE FT-950
YAE FT-980
YAE FT-990
YAE FT-991
YAE FTDX 1200
YAE FTDX401
YAE FTDX9000CONTEST
YAE FTM-10R
YAE FTM-10SR
YAE FTM-350AR
YAE FTM-350R
YAE FTM-400DR
YAE FTM-400XDR
YAE VR-120D
YAE VR-160
YAE VR-500
YAE VR-5000
YAE VX-1
YAE VX-120D
YAE VX-150(VER.A)
YAE VX-150(VER.B)
YAE VX-170
YAE VX-177
YAE VX-188
YAE VX-1R
YAE VX-2R
YAE VX-2R(EXP.VER.)
YAE VX-300
YAE VX-300 (VER.A)
YAE VX-300(1X)
YAE VX-3R
YAE VX-400 (VER.A)
YAE VX-500(4X)
YAE VX-5R
YAE VX-6E
YAE VX-6R
YAE VX-7R
YAE VX-7R(EXP.VER.)
YAE VX-8DR
YAE VX-8GR
YAE VX-8R
YAE Y-51R
YLT YLT-F16
YOU TJ2B
YSH AT-V80 (VHF)
YSH LS-A188S(UHF)
YSH LS-E320
YSH LS-V10
YSHON LS-28H
YUP MVT-3100
YUP MVT-7000
YUP MVT-7100
YUP MVT-8000
`}</pre>


                    </div>
                </div>

            </div>


        </Layout>

    )
}

export default IndexPage
